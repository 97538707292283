'use client';

import {useEffect} from 'react';
import Gleap from 'gleap';

const GleapChat = () => {
    useEffect(() => {
        Gleap.initialize('bjgLLexZCwwUPccTTugOKUBgazEqvtRZ');
    });

    return null;
};

export default GleapChat;
